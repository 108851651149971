// Global styling for this template
html {
    position: relative;
    min-height: 100%;
}

body {
    width: 100%;
    height: 100%;
    margin-bottom: 60px;
}

body {
    @include body-font;
}

a {
    color: $theme-primary;
    @include transition-all;
    &:hover,
    &:focus {
        color: darken($theme-primary, 10%);
    }
}

hr {
    max-width: 100px;
    margin: 25px auto 0;
    border-width: 1px;
    border-color: fade-out($gray-darker, .9);
}

hr.light {
    border-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include heading-font;
}

p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
}

section {
    padding: 100px 0;
    h2 {
        font-size: 50px;
    }
}

.nav-tabs .nav-link svg {
    margin-right: 0.25rem;
    line-height: 1;
    font-size: 0.975rem;
    width: 0.975rem;
    vertical-align: baseline;
    display: inline-block;
}

.page-content-bottom {
    margin: 0.75rem 0 8rem;
}

// .footer {
//     // position: absolute;
//     // bottom: 0;
//     width: 100%;
//     // height: 60px;
// }


// Styling for the footer
footer {
    // padding: 25px 0;
    // text-align: center;
    color: fade-out(white, .7);
    background-color: $gray-darker;
    // p {
    //     font-size: 12px;
    //     margin: 0;
    // }
}