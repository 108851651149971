$theme-primary:    #64DCFC;
$theme-secondary:  #3CA394;
$theme-tertiary:   #7b4397;;

$gray-base:        #000;
$gray-darker:      lighten($gray-base, 13.5%); // #222
$gray-dark:        lighten($gray-base, 20%);   // #333
$gray:             lighten($gray-base, 33.5%); // #555
$gray-light:       lighten($gray-base, 46.7%); // #777
$gray-lighter:     lighten($gray-base, 93.5%); // #eee

$brand-twitter:		#1da1f2;
$brand-facebook:	#3b5998;
$brand-google-plus:	#dd4b39;

@mixin transition-all() {
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
}

@mixin background-cover() {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@mixin heading-font {
    font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
    font-weight: 200;
    letter-spacing: 1px;
}

@mixin body-font {
    font-family: 'Muli', 'Helvetica', 'Arial', 'sans-serif';
}

@mixin alt-font {
    font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';
    letter-spacing: 2px;
    text-transform: uppercase;
}